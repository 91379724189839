import React from "react";

// This page is needed for LOMACS/FILEMAKER to redirect the myob webhook

export default function Redirect() {
  return (
    <div>
      <br />
      <br />
      <h1 className="text-center mt-4 fullscreen">Success!</h1>
    </div>
  );
}
