import React from "react";
import popup from "../../../assets/img/solutions/pop-up/image1.jpg";
import popup2 from "../../../assets/img/solutions/pop-up/image3.jpg";
import popup3 from "../../../assets/img/solutions/pop-up/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Pop Up";

const images = [
  { src: popup, alt: "Shiseido" },
  { src: popup2, alt: "Hoyts Cinema" },
  { src: popup3, alt: "Coming Soon" },
];

export default function PopUp() {
  return <SolutionsContainer title={title} images={images} />;
}
