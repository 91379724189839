import React from "react";
import ScrollLink from "../../helpers/ScrollLink";

export default function Creative() {
  return (
    <section className="fold2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="mb8 white-on-orange">
              Your creative solutions partner in visual communications
              <br />
              and branded environments.
            </h2>
            <p className="lead mb40"></p>
            <ScrollLink className="btn btn-filled btn-lg mb0" to="/contact">
              Get in touch
            </ScrollLink>
          </div>
        </div>
      </div>
    </section>
  );
}
