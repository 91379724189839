import React from "react";
import Slider from "../shared/Slider/Slider";
import sliderdata from "./sliderData";
import office from "../../assets/img/store/office.jpg";
import gyms from "../../assets/img/store/gyms.jpg";
import backdrops from "../../assets/img/store/customisable-backdrops.jpg";
import floorSticker from "../../assets/img/store/floor-stickers-0.png";
import banner from "../../assets/img/store/pull-up-banners.jpg";
import desks from "../../assets/img/store/desks.jpg";
import screens from "../../assets/img/store/hygiene-screens-0.png";
import BrandCarousel from "../shared/brandCarousel/BrandCarousel";
import Love from "../shared/getInTouch/Love";

export default function CovidSignage() {
  return (
    <div className="main-container">
      <div className="fullscreen">
        <Slider dataSlider={sliderdata} page="covid" />
      </div>
      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={office} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Workplace Wellbeing Solutions</h3>
              <p className="h5">
                We have identified 4 pillars of competencies to assist and
                deliver best practice safety and well-being systems within the
                workplace.
              </p>
              <a className="btn-filled btn" href="/safety-signage/workplace">
                More Details Here
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-4 col-sm-5 mb-xs-24">
              <h3>Fitness Industry Solutions</h3>
              <p className="h5">
                Communicate clearly with your members and optimise the use of
                space and machines safely.
              </p>
              <a className="btn-filled btn" href="/safety-signage/gym">
                More Details Here
              </a>
            </div>
            <div className="col-md-7 col-md-offset-1 col-sm-6 col-sm-offset-1 text-center">
              <img className="cast-shadow" alt="Screenshot" src={gyms} />
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={backdrops} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Customisable Backdrops</h3>
              <p className="h5">
                It’s likely you’re participating in more video calls than you
                ever could have imagined! Like us, do you have a serious case of
                bookcase envy after seeing all those commentators on national
                and international TV channels? Well no longer! We have a wealth
                of designs for you to choose from that will make you the envy of
                all.
              </p>
              <a className="btn-filled btn" href="/safety-signage/backdrop">
                More Details Here
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-4 col-sm-5 mb-xs-24">
              <h3>Floor Stickers</h3>
              <p className="h5">
                We all need to be reminded about Social Distancing, especially
                now as we start to see the easing of restrictions. Why not get a
                little bit creative with this key messaging and use one of our
                existing designs for internal external floor stickers.
              </p>
              <a
                className="btn-filled btn"
                href="/safety-signage/floor-stickers"
              >
                More Details Here
              </a>
            </div>
            <div className="col-md-7 col-md-offset-1 col-sm-6 col-sm-offset-1 text-center">
              <img
                className="cast-shadow"
                alt="Screenshot"
                src={floorSticker}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={banner} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Pull Up Banners</h3>
              <p className="h5">
                Safety is paramount at this time – Communicate with those that
                you need to in a way that stands out & simply can’t be missed .
                Light weight and completely portable Pull Up banners offer a
                solution to convey standard or customised messaging on a larger
                scale.
              </p>
              <a
                className="btn-filled btn"
                href="/safety-signage/pull-up-banners"
              >
                More Details Here
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-4 col-sm-5 mb-xs-24">
              <h3>Portable Desks</h3>
              <p className="h5">
                For those now working from home or who have children that are
                being home schooled. We have a flatpack desk that is unobtrusive
                and can be packed up and down with in seconds, with no tooling
                required. Made in two different colours of aluminium frame and
                coming with a white laminated MDF top, the desk is stylish and
                easy to set up and take down at the end of the day.
              </p>
              <a className="btn-filled btn" href="/safety-signage/desks">
                More Details Here
              </a>
            </div>
            <div className="col-md-7 col-md-offset-1 col-sm-6 col-sm-offset-1 text-center">
              <img className="cast-shadow" alt="Screenshot" src={desks} />
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={screens} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Hygiene Screens</h3>
              <p className="h5">
                The Social distancing screen is designed to be easily assembled
                and cleaned, designed to acts as a hygiene barrier between
                employees and customers in close proximity.
              </p>
              <a className="btn-filled btn" href="/safety-signage/screens">
                More Details Here
              </a>
            </div>
          </div>
        </div>
      </section>
      <BrandCarousel />
      <Love />
    </div>
  );
}
