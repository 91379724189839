import React from "react";
import hoarding from "../../../assets/img/solutions/hoardings/image1.jpg";
import hoarding1 from "../../../assets/img/solutions/hoardings/image2.jpg";
import hoarding2 from "../../../assets/img/solutions/hoardings/image3.jpg";
import hoarding3 from "../../../assets/img/solutions/hoardings/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Hoardings";

const images = [
  { src: hoarding, alt: "Hoarding 1" },
  { src: hoarding1, alt: "Hoarding 2" },
  { src: hoarding2, alt: "Hoarding 3" },
  { src: hoarding3, alt: "Hoarding 4" },
];

export default function Hoardings() {
  return <SolutionsContainer title={title} images={images} />;
}
