import React from "react";
import dysplay from "../../../assets/img/solutions/displays/image1.jpg";
import dysplay1 from "../../../assets/img/solutions/displays/image2.jpg";
import dysplay2 from "../../../assets/img/solutions/displays/image3.jpg";
import dysplay3 from "../../../assets/img/solutions/displays/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "displays";

const images = [
  { src: dysplay, alt: "Ivore" },
  { src: dysplay1, alt: "Benefic" },
  { src: dysplay2, alt: "The Season" },
  { src: dysplay3, alt: "Jo Malone" },
];

export default function Displays() {
  return <SolutionsContainer title={title} images={images} />;
}
