import React from "react";
import fabric from "../../../assets/img/solutions/fabrics/DSC_0410.jpg";
import joemalone from "../../../assets/img/solutions/fabrics/JoMalone_Myers-5.jpg";
import jimmychoo from "../../../assets/img/solutions/fabrics/Jimmy_Choo-4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Fabrics";

const images = [
  { src: fabric, alt: "Fabric" },
  { src: joemalone, alt: "Joe Malone" },
  { src: jimmychoo, alt: "Jimmy Choo" },
];
export default function Fabrics() {
  return <SolutionsContainer title={title} images={images} />;
}
