import React from "react";
import horeca from "../../../assets/img/solutions/horeca/image1.jpg";
import horeca1 from "../../../assets/img/solutions/horeca/image2.jpg";
import horeca2 from "../../../assets/img/solutions/horeca/image3.jpg";
import horeca3 from "../../../assets/img/solutions/horeca/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Horeca";
const images = [
  { src: horeca, alt: "Dagwood" },
  { src: horeca1, alt: "Wenty Leagues" },
  { src: horeca2, alt: "Honeysuckle" },
  { src: horeca3, alt: "NRL Australia" },
];

export default function Horeca() {
  return <SolutionsContainer title={title} images={images} />;
}
