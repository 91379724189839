import React from "react";
import outdoor from "../../../assets/img/solutions/outdoor/image1.jpg";
import outdoor1 from "../../../assets/img/solutions/outdoor/image2.jpg";
import outdoor2 from "../../../assets/img/solutions/outdoor/image3.jpg";
import outdoor3 from "../../../assets/img/solutions/outdoor/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Outdoor";
const images = [
  { src: outdoor, alt: "Outdoor 1" },
  { src: outdoor1, alt: "Outdoor 2" },
  { src: outdoor2, alt: "Outdoor 3" },
  { src: outdoor3, alt: "Outdoor 4" },
];

export default function Outdoor() {
  return <SolutionsContainer title={title} images={images} />;
}
