import React from "react";
import Info from "./shared/getInTouch/Info";
import file from "../assets/files/look-print-file-specifications.pdf";
import printer from "../assets/files/look-print-printer-specifications.pdf";
import Accordion from "react-bootstrap/Accordion";

export default function Technical() {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row mb64 mb-xs-24 justify-content-md-center">
            <div className="col-md-10 col-md-offset-1 col-sm-12 text-center">
              <h1 className="uppercase color-primary mb40 mb-xs-24">
                Technical
              </h1>
              <p className="lead mb80 text-left">
                With such a vast array of print and fabrication capability we
                can virtually print directly onto anything and create graphics
                and displays to suit your campaign requirements or spatial
                experience.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="feature feature-1 boxed">
                <div className="text-center">
                  <i className="ti-wand icon"></i>
                  <h4>Studio and Creative</h4>
                </div>
                <div className="text-center">
                  <ul className="lead" data-bullet="ti-arrow-right">
                    <li>Preflight</li>
                    <li>Prepress</li>
                    <li>Colour Management</li>
                    <li>Layout and Design</li>
                    <li>3D Modelling</li>
                  </ul>
                  <a
                    className="btn-filled btn"
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Artwork Specs
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="feature feature-1 boxed">
                <div className="text-center">
                  <i className="ti-printer icon"></i>
                  <h4>Print</h4>
                </div>
                <div className="text-center">
                  <ul className="lead" data-bullet="ti-arrow-right">
                    <li>UV Flatbed and Roll to Roll</li>
                    <li>Photographic</li>
                    <li>Latex</li>
                    <li>Solvent</li>
                    <li>Dye Sublimation</li>
                  </ul>
                  <a
                    className="btn-filled btn"
                    href={printer}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Printer Specs
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="feature feature-1 boxed">
                <div className="text-center">
                  <i className="ti-wand icon"></i>
                  <h4>Fabrication and Engineering</h4>
                </div>
                <div className="text-center">
                  <ul className="lead" data-bullet="ti-arrow-right">
                    <li>Builds</li>
                    <li>Cutting</li>
                    <li>Laminating</li>
                    <li>Varnishing</li>
                    <li>Welding & Sewing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="feature feature-1 boxed">
                <div className="text-center">
                  <i className="ti-bolt icon"></i>
                  <h4>Logistics and Installation</h4>
                </div>
                <div className="text-center">
                  <ul className="lead" data-bullet="ti-arrow-right">
                    <li>Install</li>
                    <li>Pick and Pack</li>
                    <li>Fulfillment</li>
                    <li>Asia Pac Delivery</li>
                    <li>Site Audit</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <h2 className="uppercase color-primary mb40 mb-xs-24 text-center pl-4">
          faqs
        </h2>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              ARTWORK What will I need to supply?
            </Accordion.Header>
            <Accordion.Body>
              Really, almost anything. You can supply us with a computer file
              that is absolutely perfect and ready to print, right through to a
              concept that we need to create for you. Perhaps you have your own
              designer. We are more than happy to talk to them directly if you
              choose. Download{" "}
              <a href={file} target="_blank" rel="noopener noreferrer">
                artwork specifications here.
              </a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              HOW LONG? When can they be ready?
            </Accordion.Header>
            <Accordion.Body>
              We can normally complete any job within 24 business hours from
              proof approval (unless it’s a huge job of course). The sooner you
              confirm that everything is just the way you want it, the sooner we
              can complete. Please contact your Look account manager for any
              more specific or urgent timeframes needed.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              COLOURS What about colour matching?
            </Accordion.Header>
            <Accordion.Body>
              We can match to an enormous gamut of colour. Speak to your Look
              account manager for colour matching options.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              FILE TRANSFER How do I send my files?
            </Accordion.Header>
            <Accordion.Body>
              Files up to 10MB can be emailed to Look. Larger files will need to
              be supplied via a transfer service such as WeTransfer or Dropbox.
              Get in touch for details.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              MATERIALS What can be digitally printed?
            </Accordion.Header>
            <Accordion.Body>
              Almost anything. From invitations, posters, acrylic, banners,
              cardboard, plastic, sheet metal, glass, flags, bathroom tiles,
              wallpaper, fabrics, and fine-art prints. The question could be
              ‘What can’t be printed’? We can advise you of what is possible,
              and what would be the best method of production for your
              particular needs. Just ask us and we’ll share our expertise and
              insights.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              MY DESIGNER Can Look work directly with them?
            </Accordion.Header>
            <Accordion.Body>
              We’d love to work with your designer. You have a great
              relationship with them, and they know what you want. We know
              digital printing and can work with them to make sure everything
              looks great when it’s finished. Our job is to help your designer
              to get the files right, and to get it printed.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>

      <Info />
    </div>
  );
}
