import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import LogoLight from "../assets/img/logo-light.png";
import ScrollLink from "./helpers/ScrollLink";

function NavTabs() {
  return (
    <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
      <Container fluid className="align-middle fixed">
        <Navbar.Brand as={ScrollLink} to="/">
          <img className="logo" alt="Look" src={LogoLight} />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="navitems text-right">
            <Nav.Link as={ScrollLink} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="work">
              Why work with us?
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="solutions">
              Solutions
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="technical">
              Technical
            </Nav.Link>
            <Nav.Link as={ScrollLink} to="safety-signage">
              Safety Signage
            </Nav.Link>
            <ScrollLink to="contact" className="btn btn-white header-button">
              Contact Us
            </ScrollLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavTabs;
