import React from "react";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";
import backdrop from "../../../assets/img/store/customisable-backdrops-0.jpg";
import backdrop1 from "../../../assets/img/store/customisable-backdrops-1.jpg";
import backdrop2 from "../../../assets/img/store/customisable-backdrops-2.jpg";
import backdrop3 from "../../../assets/img/store/customisable-backdrops-3.jpg";
export default function Backdrops() {
  const title = (
    <>
      Customisable
      <br /> Backdrops
    </>
  );
  const description = (
    <>
      <p className="lead">
        It’s likely you’re participating in more video calls than you ever could
        have imagined! Like us, do you have a serious case of bookcase envy
        after seeing all those commentators on national and international TV
        channels? Well no longer! We have a wealth of designs for you to choose
        from that will make you the envy of all.
      </p>
      <p className="lead">
        From a bookcase that rivals any library, to a branded media wall, to
        something much more quirky & fun - we have you covered - you can even
        bring any design of choice.
      </p>
      <p className="lead">
        Available as a pull-up banner, rigid board or in fabric.
      </p>
      <br />
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Customisable Backdrops";

  const images = [
    { src: backdrop, alt: "BackDrop 1" },
    { src: backdrop1, alt: "BackDrop2" },
    { src: backdrop2, alt: "BackDrop3" },
    { src: backdrop3, alt: "BackDrop 4" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
