import React from "react";
import projectmana from "../assets/img/wwwu-projectmanagement.jpg";
import studio from "../assets/img/wwwu-studio.jpg";
import printers from "../assets/img/wwwu-printers.jpg";
import fabrication from "../assets/img/wwwu-fabrication.jpg";
import logistics from "../assets/img/wwwu-logistics.jpg";
import BrandCarousel from "./shared/brandCarousel/BrandCarousel";

export default function Work() {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row mb64 mb-xs-24 justify-content-md-center">
            <div className="col-md-10 col-md-offset-1 col-sm-12 text-center">
              <h1 className="uppercase color-primary mb40 mb-xs-24">
                Why work with us?
              </h1>
              <p className="lead text-left mb-4">
                At Look we thrive on developing long term, mutually beneficial
                relationships with our clients.
              </p>
              <p className="lead text-left mb-4">
                We realise that every business is different, and therefore has
                unique needs when it comes to visual communications and
                printing. When you come to us you are not simply buying print –
                you are buying a communications solution to build sales or
                create a spatial experience. This is why we have a team of
                Concept Consultants with backgrounds in Marketing, Visual
                Merchandising and Design, to collaborate on your projects.
                <br />
              </p>
              <p className="lead text-left mb-4">
                Our consultants will work closely with you to deliver on your
                specific campaign needs or can advise you on alternative
                products and innovative solutions that could further enhance the
                outcomes required. Our R&D team continuously travel the world
                sourcing the latest concepts, materials and technology to bring
                you the highest quality, innovative products and services at
                competitive market prices.
                <br />
              </p>
              <p className="lead text-left">
                We understand quality and reliability are paramount. That’s why
                for nearly 30 years Look have been, and are still considered to
                be THE industry standard for reliability and innovation in
                quality printing, visual communications and branded
                environments.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={projectmana} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Project Teams</h3>
              <h5>
                <p>
                  Dedicated project teams are allocated to each client and job,
                  ensuring we understand the dynamics of your business and
                  develop the right solutions for your needs every time.
                </p>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-4 col-sm-5 mb-xs-24">
              <h3>Studio and Creative</h3>
              <h5>
                <p>
                  From print-ready files through to creative and design
                  collaboration on your graphic and build projects, our
                  passionate studio team would be delighted to help you to bring
                  your ideas to life.
                </p>
                <p>
                  Is colour accuracy also important to you? Our unique and fully
                  integrated, ISO certified, colour management system means we
                  get colour right every time.
                </p>
              </h5>
            </div>
            <div className="col-md-7 col-md-offset-1 col-sm-6 col-sm-offset-1 text-center">
              <img className="cast-shadow" alt="Screenshot" src={studio} />
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={printers} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Print and Production</h3>
              <h5>
                <p>
                  With 10 different print technologies, 20 printers, 50 staff
                  members and over 3000m² of production facilities our total
                  print capacity is currently more than 500m²/hr, positioning us
                  as one of Australia’s largest capacity large format digital
                  printers.
                </p>
                <p>Our commitment to print quality is second to none.</p>
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-4 col-sm-5 mb-xs-24">
              <h3>Fabrication and Engineering</h3>
              <h5>
                <p>
                  Our highly skilled fabrication team is experienced and best in
                  className in the many facets of fabricating and engineering
                  both temporary and permanent graphic projects. From CWT to
                  mounting to highly complex builds, we'll help you stand out
                  from the crowd in any environment.
                </p>
              </h5>
            </div>
            <div className="col-md-7 col-md-offset-1 col-sm-6 col-sm-offset-1 text-center">
              <img className="cast-shadow" alt="Screenshot" src={fabrication} />
            </div>
          </div>
        </div>
      </section>

      <section className="fold1">
        <div className="container">
          <div className="row v-align-children">
            <div className="col-md-7 col-sm-6 text-center mb-xs-24">
              <img className="cast-shadow" alt="Screenshot" src={logistics} />
            </div>
            <div className="col-md-4 col-md-offset-1 col-sm-5 col-sm-offset-1">
              <h3>Logistics and Installation</h3>
              <h5>
                <p>
                  We understand how important it is for your campaigns and
                  projects to look amazing and to be on time. We select and work
                  with only the best logistics providers nationally and across
                  Asia Pacific so we can make this promise to you.
                </p>
              </h5>
            </div>
          </div>
        </div>
      </section>

      <BrandCarousel />

      <section className="fold2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="mb8 white-on-orange">
                We love doing what others don't, won’t, or can't.
              </h2>
              <p className="lead mb40">Talk to our team about new ideas.</p>
              <a className="btn btn-filled btn-lg mb0" href="/contact/">
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
