import React from "react";
import containerData from "./containerData";
import Mind from "../shared/getInTouch/Mind";

export default function Solutions() {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 text-center">
              <h1 className="uppercase color-primary mb40 mb-xs-24">
                Solutions
              </h1>
              <p className="lead mb80">
                Below is just a small sample of what Look can offer your
                business.
              </p>
            </div>
          </div>

          <div className="row mb64 mb-xs-32">
            {containerData.map((data, index) => (
              <div key={index} className="col-sm-6 mb-4">
                <div className="image-caption cast-shadow mb-xs-32">
                  <a href={data.href}>
                    <img alt={data.alt} loading="lazy" src={data.src} />
                    <div className="caption">
                      <p>
                        <strong>{data.description}</strong>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Mind />
    </div>
  );
}
