import React from "react";
import ScrollLink from "../../helpers/ScrollLink";

export default function Love() {
  return (
    <section className="fold2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="mb8 white-on-orange">
              We love doing what others don't, won’t, or can't.
            </h2>
            <p className="lead mb40">Talk to our team about new ideas.</p>
            <ScrollLink className="btn btn-filled btn-lg mb0" to="/contact">
              Get in touch
            </ScrollLink>
          </div>
        </div>
      </div>
    </section>
  );
}
