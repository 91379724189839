import React from "react";
import image1 from "../../../assets/img/store/office-1.jpg";
import image2 from "../../../assets/img/store/office-2.jpg";
import image3 from "../../../assets/img/store/office-3.jpg";
import image4 from "../../../assets/img/store/office-4.jpg";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";

export default function Workplace() {
  const title = "Workplace Wellbeing Solutions";
  const description = (
    <>
      <p className="lead">
        In providing a service that is so much more than simply execution, we
        have created a set of communication products that will enable you to
        remind your team about best practice and ongoing wellbeing.
      </p>
      <h4 className="mb0">PLAN</h4>
      <ul className="mb16">
        <li> • Site review</li>
        <li> • Overlay plan</li>
        <li> • Designed to incorporate your brand & values</li>
      </ul>
      <h4 className="mb0">COMMUNICATION</h4>
      <ul className="mb16">
        <li> • Workstation social distance indicators</li>
        <li> • One-way foot traffic directions</li>
        <li> • Hygiene & social distancing reminders</li>
      </ul>
      <h4 className="mb0">CONTROLS</h4>
      <ul className="mb16">
        <li> • Acrylic screen dividers</li>
        <li> • Hand sanitiser stations</li>
        <li> • Hygiene & high touch cleaning solutions</li>
      </ul>
      <h4 className="mb0">REVIEW</h4>
      <ul className="mb16">
        <li> • Ongoing information updates</li>
        <li> • Advice on legislation changes</li>
        <li> • Continuous solution support</li>
      </ul>

      <p className="lead">
        Please don’t hesitate to <a href="/contact">contact us</a> and we can
        tailor a solution to suit your needs.
      </p>
    </>
  );

  const carouselTitle = "Workplace Wellbeing Solutions";

  const images = [
    { src: image1, alt: "image 1" },
    { src: image2, alt: "image 2" },
    { src: image3, alt: "image 3" },
    { src: image4, alt: "image 4" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
