import React from "react";
import wallpaper from "../../../assets/img/solutions/wallpaper/image1.jpg";
import wallpaper1 from "../../../assets/img/solutions/wallpaper/image2.jpg";
import wallpaper2 from "../../../assets/img/solutions/wallpaper/image3.jpg";
import wallpaper3 from "../../../assets/img/solutions/wallpaper/image4.jpg";
import colletteDinnigan from "../../../assets/img/solutions/wallpaper/ColletteDinnigan-11.jpg";
import colletteDinnigan1 from "../../../assets/img/solutions/wallpaper/ColletteDinnigan.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Wallpaper";

const images = [
  { src: wallpaper, alt: "Wallpaper 1" },
  { src: wallpaper1, alt: "Wallpaper 2" },
  { src: wallpaper2, alt: "Wallpaper 3" },
  { src: wallpaper3, alt: "Wallpaper 4" },
  { src: colletteDinnigan, alt: "collette Dinnigan" },
  { src: colletteDinnigan1, alt: "collette Dinnigan" },
];

export default function Wallpaper() {
  return <SolutionsContainer title={title} images={images} />;
}
