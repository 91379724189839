import React from "react";
import event from "../../../assets/img/solutions/events/image1.jpg";
import event1 from "../../../assets/img/solutions/events/image2.jpg";
import event2 from "../../../assets/img/solutions/events/image3.jpg";
import event3 from "../../../assets/img/solutions/events/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Events";

const images = [
  { src: event, alt: "Howard Park" },
  { src: event1, alt: "Liquidation" },
  { src: event2, alt: "Opera Event" },
  { src: event3, alt: "Myer" },
];

export default function Events() {
  return <SolutionsContainer title={title} images={images} />;
}
