import React from "react";
import fitout from "../../../assets/img/solutions/office-fitout/image1.jpg";
import fitout1 from "../../../assets/img/solutions/office-fitout/image2.jpg";
import fitout2 from "../../../assets/img/solutions/office-fitout/image3.jpg";
import fitout3 from "../../../assets/img/solutions/office-fitout/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Office Fitout";

const images = [
  { src: fitout, alt: "Office Fitout 1" },
  { src: fitout1, alt: "Office Fitout 2" },
  { src: fitout2, alt: "Office Fitout 3" },
  { src: fitout3, alt: "Office Fitout 4" },
];

export default function OfficeFitout() {
  return <SolutionsContainer title={title} images={images} />;
}
