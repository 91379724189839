import React from "react";
import window from "../../../assets/img/solutions/windows/image1.jpg";
import window1 from "../../../assets/img/solutions/windows/image2.jpg";
import window2 from "../../../assets/img/solutions/windows/image3.jpg";
import window3 from "../../../assets/img/solutions/windows/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Windows";

const images = [
  { src: window, alt: "David Jones" },
  { src: window1, alt: "Clinique Gift" },
  { src: window2, alt: "Estee Lauder" },
  { src: window3, alt: "Tag Heuer" },
];

export default function Windows() {
  return <SolutionsContainer title={title} images={images} />;
}
