import React from "react";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";
import desk from "../../../assets/img/store/desks-0.jpg";
import desk1 from "../../../assets/img/store/desks-1.jpg";
import desk2 from "../../../assets/img/store/desks-2.jpg";
import desk3 from "../../../assets/img/store/desks-3.jpg";

export default function Desks() {
  const title = "Portable Desks";
  const description = (
    <>
      <p className="lead">
        In these times of COVID-19 we are strongly encouraged to work from home
        in order to stop the spread of this virus. Many of us are now keeping
        our children home from school as well, our houses are becoming hives of
        study and work with little room left at the dinner table.
      </p>
      <p className="lead">
        This has created an urgent need for us to find new ways to set up our
        work from home spaces. The creative team at Look has come up with a
        light weight workspace, easily packed up at the end of each day with no
        tools required.
      </p>
      <p className="lead">
        Unobtrusive for most small homes and apartments but comfortable enough
        for all work types. Simply click together and get started.
      </p>
      <p className="lead">
        Lightweight aluminium frame with a 10mm MDF laminate top
      </p>
      <p className="lead">Available in two sizes -</p>
      <p className="lead">
        Adult 600mm (W) x 750mm (H) x 400mm (D) <br />
        Junior 600mm (W) x 500mm (H) x 400mm (D)
        <br />
      </p>
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Portable Desks";

  const images = [
    { src: desk, alt: "Desk" },
    { src: desk1, alt: "Desk 1" },
    { src: desk2, alt: "Desk 2" },
    { src: desk3, alt: "Desk 3" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
