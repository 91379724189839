import React from "react";
import cladding from "../../../assets/img/solutions/cladding/image1.jpg";
import cladding1 from "../../../assets/img/solutions/cladding/image2.jpg";
import cladding2 from "../../../assets/img/solutions/cladding/image3.jpg";
import cladding3 from "../../../assets/img/solutions/cladding/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Cladding";

const images = [
  { src: cladding, alt: "Red Cladding" },
  { src: cladding1, alt: "Window Cladding" },
  { src: cladding2, alt: "Coffee Cladding" },
  { src: cladding3, alt: "Building" },
];

export default function Cladding() {
  return <SolutionsContainer title={title} images={images} />;
}
