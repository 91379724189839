import React from "react";
import screens from "../../../assets/img/store/hygiene-screens-1.png";
import screens1 from "../../../assets/img/store/hygiene-screens-2.png";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";

export default function Screens() {
  const title = "Hygiene Screens – Temporary";

  const description = (
    <>
      <p className="lead">
        Created to provide comfort and relief for all that come into contact
        with it, the Countertop Screen can come in both landscape or portrait
        orientations and can be branded with your logo, messaging or a colour
        scheme of your choice. Built from 5mm coreflute with a protective
        polycarbonate centre that forms a shield that may help prevent the
        direct physical and airborne spread of germs.
      </p>
      <p className="lead">
        An opening at the bottom of the partition allows for the transfer of
        items,, or payment for transactions. Easy to clean with any common
        disinfectant, social distance screen is also lightweight, portable, and
        fully collapsible, making it versatile and easy to store.
      </p>
      <p className="lead">
        Social distancing protocols cannot always be followed. This counter top
        Screen may significantly lower the health safety risk for employees and
        customers.
      </p>
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Hygiene Screens";

  const images = [
    { src: screens, alt: "Screen" },
    { src: screens1, alt: "Screen 1" },
    { src: screens, alt: "Screen 2" },
    { src: screens1, alt: "Screen 3" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
