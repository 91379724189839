import { v4 as uuidv4 } from "uuid";
import Home from "./components/home/Home";
import CovidSignage from "./components/covidSignage/CovidSignage";
import {
  Gym,
  Backdrops,
  FloorStickers,
  Banners,
  Desks,
  Screens,
  Workplace,
} from "./components/covidSignage/subpages";
import Work from "./components/Work";
import Solutions from "./components/solutions/Solutions";
import {
  Art,
  BacklitDisplay,
  BrandedEnvironments,
  Cladding,
  DisplaySystem,
  Displays,
  Events,
  Fabrics,
  Galleries,
  Hoardings,
  Horeca,
  OfficeFitout,
  Outdoor,
  PopUp,
  Wallpaper,
  Windows,
} from "./components/solutions/subpages";
import Technical from "./components/Technical";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import TermsAndConditions from "./components/TermsAndConditions";
import MyobRedirect from "./components/MyobRedirect";
import NotFound from "./components/Notfound";

const AppRoutes = [
  {
    id: uuidv4(),
    index: true,
    element: <Home />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage",
    element: <CovidSignage />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/workplace",
    element: <Workplace />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/gym",
    element: <Gym />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/backdrop",
    element: <Backdrops />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/floor-stickers",
    element: <FloorStickers />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/pull-up-banners",
    element: <Banners />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/desks",
    element: <Desks />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/screens",
    element: <Screens />,
  },
  {
    id: uuidv4(),
    path: "/safety-signage/screens",
    element: <Screens />,
  },
  {
    id: uuidv4,
    path: "/work",
    element: <Work />,
  },
  {
    id: uuidv4,
    path: "/solutions",
    element: <Solutions />,
  },
  {
    id: uuidv4,
    path: "/solutions/art",
    element: <Art />,
  },
  {
    id: uuidv4,
    path: "/solutions/backlit-displays",
    element: <BacklitDisplay />,
  },
  {
    id: uuidv4,
    path: "/solutions/branded-environments",
    element: <BrandedEnvironments />,
  },
  {
    id: uuidv4,
    path: "/solutions/cladding",
    element: <Cladding />,
  },
  {
    id: uuidv4,
    path: "/solutions/displays",
    element: <Displays />,
  },
  {
    id: uuidv4,
    path: "/solutions/events",
    element: <Events />,
  },
  {
    id: uuidv4,
    path: "/solutions/galleries",
    element: <Galleries />,
  },
  {
    id: uuidv4,
    path: "/solutions/display-system",
    element: <DisplaySystem />,
  },
  {
    id: uuidv4,
    path: "/solutions/hoardings",
    element: <Hoardings />,
  },
  {
    id: uuidv4,
    path: "/solutions/horeca",
    element: <Horeca />,
  },
  {
    id: uuidv4,
    path: "/solutions/office-fitout",
    element: <OfficeFitout />,
  },
  {
    id: uuidv4,
    path: "/solutions/outdoor",
    element: <Outdoor />,
  },
  {
    id: uuidv4,
    path: "/solutions/popup",
    element: <PopUp />,
  },
  {
    id: uuidv4,
    path: "/solutions/wallpaper",
    element: <Wallpaper />,
  },
  {
    id: uuidv4,
    path: "/solutions/windows",
    element: <Windows />,
  },
  {
    id: uuidv4,
    path: "/solutions/fabrics",
    element: <Fabrics />,
  },
  {
    id: uuidv4,
    path: "/technical",
    element: <Technical />,
  },
  {
    id: uuidv4,
    path: "/contact",
    element: <Contact />,
  },
  {
    id: uuidv4,
    path: "/privacy",
    element: <Privacy />,
  },
  {
    id: uuidv4,
    path: "/TermsAndConditions",
    element: <TermsAndConditions />,
  },
  {
    id: uuidv4,
    path: "/oauth/redirect/",
    element: <MyobRedirect />,
  },
  {
    id: uuidv4,
    path: "*",
    element: <NotFound />,
  },
];

export default AppRoutes;
