import React from "react";
import enviroment from "../../../assets/img/solutions/branded-environments/image1.jpg";
import enviroment1 from "../../../assets/img/solutions/branded-environments/image2.jpg";
import enviroment2 from "../../../assets/img/solutions/branded-environments/image3.jpg";
import enviroment3 from "../../../assets/img/solutions/branded-environments/image4.jpg";
import astraZeneca from "../../../assets/img/solutions/branded-environments/Astra_Zeneca-18.jpg";
import cochlear from "../../../assets/img/solutions/branded-environments/cochlear.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Branded Environments";

const images = [
  { src: enviroment, alt: "Green Brand" },
  { src: enviroment1, alt: "Clinic" },
  { src: enviroment2, alt: "Haul" },
  { src: enviroment3, alt: "Blue Glass" },
  { src: astraZeneca, alt: "Astra Zeneca" },
  { src: cochlear, alt: "Cochlear" },
];

export default function BrandedEnvironments() {
  return <SolutionsContainer title={title} images={images} />;
}
