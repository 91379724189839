import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import brand1 from "../../../assets/img/big-brand-1.png";
// import brand2 from "../../../assets/img/big-brand-2.png";
import brand3 from "../../../assets/img/big-brand-3.png";
import brand4 from "../../../assets/img/big-brand-4.png";

const responsive = {
  desktop: {
    breakpoint: { max: 8000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

export default function BrandCarousel() {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h4 className="uppercase mb64 mb-xs-40">
              All the big brands love Look
            </h4>
          </div>
        </div>
        <div className="logo-carousel">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            customTransition="all .5"
            transitionDuration={500}
            arrows={false}
          >
            <img alt="Logo" src={brand1} />
            {/* <img alt="Logo" src={brand2} /> */}
            <img alt="Logo" src={brand3} />
            <img alt="Logo" src={brand4} />
            <img alt="Logo" src={brand1} />
            {/* <img alt="Logo" src={brand2} /> */}
            <img alt="Logo" src={brand3} />
            <img alt="Logo" src={brand4} />
            <img alt="Logo" src={brand1} />
            <img alt="Logo" src={brand3} />
            <img alt="Logo" src={brand4} />
          </Carousel>
        </div>
      </div>
    </section>
  );
}
