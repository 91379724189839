import React from "react";

export default function TermsAndConditions() {
  return (
    <div className="main-container">
      <section className="pb-0">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <h1 className="uppercase color-primary">Terms and Conditions</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="main-container">
        <section>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-sm-10 col-sm-offset-1">
                <p>
                  <strong>1.</strong>         <strong>Interpretation</strong>  {" "}
                  <span></span>
                </p>
                <p>
                  In these conditions unless the contrary intention appears:
                  <span></span>
                </p>
                <p>
                  “<strong>Additional Charges</strong>” includes all delivery,
                  handling and storage charges, goods and services tax, stamp
                  duty, interest, legal and other costs of recovery of unpaid
                  money and all other government imposts and all money, other
                  than the Purchase Price, payable by the Customer to Look
                  Corporate arising out of the sale of the Goods.<span></span>
                </p>
                <p>
                   “<strong>Customer</strong>” means the person to or for whom
                  the Goods are to be supplied by Look Corporate.  <span></span>
                </p>
                <p>
                  “<strong>Goods</strong>” means the goods sold to the Customer
                  by Look Corporate and any related services provided by Look
                  Corporate.<span></span>
                </p>
                <p>
                  “<strong>Intellectual Property</strong>” means Look
                  Corporate’s  business names, trade names, trade marks, product
                  names, copyright, patents, designs, industrial processes,
                  trade secrets, know how and other intellectual property rights
                  and any development in such intellectual property rights
                  <span></span>
                </p>
                <p>
                  “<strong>Look Corporate</strong>” means Look Corporate Pty
                  Ltd, ABN 58 001 717 193.<span></span>
                </p>
                <p>
                  “<strong>Purchase Price</strong>” means the list price for the
                  goods as charged by Look Corporate at the date of delivery or
                  such other price as may be agreed by Look Corporate and the
                  Customer prior to delivery of the Goods.<span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>2.</strong>         <strong>Order for Goods</strong>
                  <span></span>
                </p>
                <p>
                  2.1       An order given to Look Corporate is binding on Look
                  Corporate and the Customer, if:<span></span>
                </p>
                <p>
                  (a)           a written acceptance is signed for or on behalf
                  of Look Corporate; or<span></span>
                </p>
                <p>
                  (b)           the Goods are supplied by Look Corporate in
                  accordance with the order.<span></span>
                </p>
                <p>
                               The Customer must place all orders to Look
                  Corporate in writing specifying order number, date of order,
                  goods required, requested delivery date, quotation reference
                  number, place of delivery and full name and title of person
                  placing the order. <span></span>
                </p>
                <p>
                  2.2       An acceptance of the order by Look Corporate is then
                  to be an acceptance of these Terms and Conditions by Look
                  Corporate and the Customer and these Terms and Conditions will
                  override any conditions contained in the Customer’s order. 
                  Look Corporate reserves the right to accept a part only of any
                  order by notifying the Customer in writing or by delivering
                  the Goods to the Customer.  No order is binding on Look
                  Corporate until accepted by it.<span></span>
                </p>
                <p>
                  2.3       An order which has been accepted in whole or in part
                  by Look Corporate cannot be cancelled by the Customer without
                  obtaining the prior written approval of Look Corporate, which
                  it may refuse in its absolute discretion.<span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>3.         Limitation of Liability</strong>
                  <span></span>
                </p>
                <p>
                  3.1       The liability of Look Corporate is limited, to the
                  extent permissible by law and at the option of Look Corporate:{" "}
                  <span></span>
                </p>
                <p>
                               in relation to goods, to;<span></span>
                </p>
                <p>
                  (a)           replacing the Goods or the supply of equivalent
                  Goods; <span></span>
                </p>
                <p>
                  (b)           the repair of the Goods;<span></span>
                </p>
                <p>
                  (c)           the payment of the cost of replacing the Goods
                  or of acquiring equivalent Goods; or <span></span>
                </p>
                <p>
                  (d)           the payment of the cost of having the Goods
                  repaired; and <span></span>
                </p>
                <p>
                  in relation to services, to: <span></span>
                </p>
                <p>
                  (a)           the supply of the services again; and{" "}
                  <span></span>
                </p>
                <p>
                  (b)           the payment of the cost of having the services
                  supplied again. <span></span>
                </p>
                <p>
                  3.2       To the extent permitted by law, all other warranties
                  whether implied or otherwise, not set out in these Terms and
                  Conditions or in specific warranties accompanying the Goods,
                  are excluded and Look Corporate is not liable in contract,
                  tort (including, without limitation, negligence or breach of
                  statutory duty) or otherwise to compensate the Customer for:{" "}
                  <span></span>
                </p>
                <p>
                  (a)           any increased costs or expenses; <span></span>
                </p>
                <p>
                  (b)           any loss of profit, revenue, business, contracts
                  or anticipated savings; <span></span>
                </p>
                <p>
                  (c)           any loss or expense resulting from a claim by a
                  third party; or <span></span>
                </p>
                <p>
                  (d)           any special, indirect or consequential loss or
                  damage of any nature whatsoever.  <span></span>
                </p>
                <p>
                  3.3       Any claims to be made against Look Corporate for
                  short delivery of Goods must be lodged with Look Corporate in
                  writing within 7 days of the delivery date.<span></span>
                </p>
                <p>
                  3.4       Unless the terms and warranties are included in
                  these Terms and Conditions or are provided in specific
                  warranties accompanying the Goods, all prior discussions,
                  quotations, warranties to the extent permitted by law, are
                  excluded.<span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>4.</strong>         <strong>Delivery</strong>
                  <span></span>
                </p>
                <p>
                  4.1       The times quoted for delivery are estimates only and
                  Look Corporate accepts no liability for failure or delay in
                  delivery of Goods.  The Customer is not relieved of any
                  obligation to accept or pay for Goods by reason of any delay
                  in delivery.  Goods may be delivered by instalments at the
                  discretion of Look Corporate.  <span></span>
                </p>
                <p>
                  4.2       The Customer must provide suitable access for
                  unloading and appropriate labour or mechanical means to unload
                  the Goods upon delivery to the Customer’s nominated premises. 
                  The Customer is liable for all costs and charges therein.
                  <span></span>
                </p>
                <p>
                  4.3       Unless otherwise agreed, delivery shall be deemed to
                  have taken place: <span></span>
                </p>
                <p>
                  (a)           if the Goods are to be collected from the Look
                  Corporate’s premises, then:              <span></span>
                </p>
                <p>
                  (i)   just prior to the Goods being loaded onto   the carrier
                  organised by the Customer; or <span></span>
                </p>
                <p>
                  (ii) at the time and date when the Goods are scheduled to be
                  collected by the Customer, <span></span>
                </p>
                <p>
                               whichever occurs first; or <span></span>
                </p>
                <p>
                  (b)           if the Goods are to be delivered to Customer’s
                  address as nominated by the Customer on the order, then just
                  prior to the Goods being unloaded from the carrier at the
                  nominated address in accordance with the order, which order is
                  delivered to the Customer at the Customer’s expense.{" "}
                  <span></span>
                </p>
                <p>
                  4.3       Signature of any delivery note by any agent,
                  employee or representative of the Customer or where delivery
                  is to any independent carrier, by such carrier or its agent,
                  shall be conclusive proof of delivery.<span></span>
                </p>
                <p>
                  4.4       Risk in accepting the Goods passes on delivery to
                  the Customer. <span></span>
                </p>
                <p>
                  4.5       All Additional Charges are payable by the Customer
                  in addition to the Purchase Price of the Goods.  <span></span>
                </p>
                <p>
                  4.6       Return of Goods will not be accepted by Look
                  Corporate except by prior agreement in writing with Look
                  Corporate.  Any Goods returned will be subject to a restocking
                  charge of 10% of the Purchase Price of those Goods.
                  <span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>5.</strong>         <strong>Price and Payment</strong>
                  <span></span>
                </p>
                <p>
                  5.1       The Customer must pay the Purchase Price and the
                  Additional Charges to Look Corporate.  <span></span>
                </p>
                <p>
                  5.2       If the Customer is in default, Look Corporate may at
                  its option withhold further deliveries or cancel a contract
                  without prejudice to any of its existing rights. {" "}
                  <span></span>
                </p>
                <p>
                  5.3       Where Look Corporate has not agreed to grant credit
                  to the Customer, all payments must be made by the Customer in
                  advance or on delivery of the Goods.  Where Look Corporate has
                  agreed to grant credit to the Customer, all payments are due
                  within 14 days of the date of invoice or as otherwise agreed
                  by Look Corporate and the Customer in writing.<span></span>
                </p>
                <p>
                  5.4       The Customer must pay interest on overdue amounts
                  calculated at the rate of 1% per month or part of a month from
                  the due date until the date full payment is received by Look
                  Corporate.<span></span>
                </p>
                <p>
                  5.5       The Customer must pay an account keeping fee of $20
                  per month or part month thereof on any accounts that are
                  overdue.  <span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>6.</strong>        {" "}
                  <strong>Retention of Title</strong>
                  <span></span>
                </p>
                <p>
                  6.1       Ownership, title and property of the Goods remains
                  with Look Corporate until payment in full for the Goods and
                  all sums due and owing by the Customer to Look Corporate on
                  any account has been made.  Until the date of payment:
                  <span></span>
                </p>
                <p>
                  (a)           the Customer has the right to sell the Goods in
                  the ordinary course of business and:<span></span>
                </p>
                <p>
                                  (i)         where the Customer is paid by the
                  third party, the Customer must hold the whole of the proceeds
                  of the sale on trust for Look Corporate; or <span></span>
                </p>
                <p>
                                  (ii)        where the Customer has not been
                  paid by the third party, the Customer, at Look Corporate’s
                  option, agrees to assign its claims against the third party to
                  Look Corporate upon Look Corporate giving written notice in
                  writing to that effect; <span></span>
                </p>
                <p>
                  (b)           until the Goods have been sold by the Customer
                  in the ordinary course of the Customer’s business, the
                  Customer holds the Goods as bailee for Look Corporate;
                  <span></span>
                </p>
                <p>
                  (c)           the Goods are always at the risk of the
                  Customer.  <span></span>
                </p>
                <p>
                  6.2       The Customer is deemed to be in default immediately
                  upon the happening of any of the following events:
                  <span></span>
                </p>
                <p>
                  (a)           if any payment to Look Corporate is not made
                  promptly before the due date for payment;<span></span>
                </p>
                <p>
                  (b)           if the Customer ceases to carry on business or
                  stops or suspends payment or states its intention of so doing
                  or is unable to pay its debts as they fall due or if any
                  cheque or bill of exchange drawn by the Customer payable to
                  Look Corporate is dishonoured.<span></span>
                </p>
                <p>
                  6.3       In the event of a default by the Customer, then
                  without prejudice to any other rights which Look Corporate may
                  have at law or under these Terms and Conditions:<span></span>
                </p>
                <p>
                  (a)           Look Corporate or its agents may without notice
                  to the Customer enter the Customer’s premises or any premises
                  under the control of the Customer for the purposes of
                  recovering the Goods.<span></span>
                </p>
                <p>
                  (b)           Look Corporate may recover and resell the Goods;
                  <span></span>
                </p>
                <p>
                  (c)           if the Goods cannot be distinguished from
                  similar Goods which the Customer has or claims to have paid
                  for in full, Look Corporate may in its absolute discretion
                  seize all goods matching the description of the Goods and hold
                  same for a reasonable period so that the respective claims of
                  Look Corporate and the Customer may be ascertained.  Look
                  Corporate must promptly return to the Customer any goods the
                  property of the Customer and Look Corporate is in no way
                  liable or responsible for any loss or damage to the Goods or
                  for any loss, damage or destruction to the Customer’s business
                  howsoever arising from the seizure of the Goods.<span></span>
                </p>
                <p>
                  (d)           In the event that the Customer uses the Goods in
                  some manufacturing or construction process of its own or some
                  third party, then the Customer must hold such part of the
                  proceeds of sale of such manufacturing or construction process
                  as relates to the Goods in trust for Look Corporate.  Such
                  part will be an amount equal in dollar terms to the amount
                  owing by the Customer to the Look Corporate at the time of the
                  receipt of such proceeds. The Customer will pay Look Corporate
                  such funds held in trust upon the demand of Look Corporate.
                  <span></span>
                </p>
                <p>
                  (e)           The Customer must pay to Look Corporate all
                  reasonable costs and disbursements incurred by Look Corporate
                  in pursuing any recovery action, or any other claim or remedy
                  against the Customer, including debt recovery fees and legal
                  fees incurred by Look Corporate on a solicitor client basis
                  and as a liquidated sum.  <span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    7.         Personal Properties Securities Act (“PPSA”)
                    <span></span>
                  </strong>
                </p>
                <p>
                  7.1       Defined terms in this clause have the same meaning
                  as given to them in the PPSA.<span></span>
                </p>
                <p>
                  7.2       Look Corporate and Customer acknowledge that these
                  Terms and Conditions constitute a Security Agreement and give
                  rise to a Purchase Money Security Interest (“PMSI”) in favour
                  of Look Corporate over the Goods supplied or to be supplied to
                  the Customer as Grantor pursuant to the Terms and Conditions.{" "}
                  <span></span>
                </p>
                <p>
                  7.3       The Goods supplied or to be supplied under these
                  Terms and Conditions fall within the PPSA classification of
                  ”Other Goods” acquired by the Customer pursuant to these Terms
                  and Conditions.  <span></span>
                </p>
                <p>
                  7.4       Look Corporate and the Customer acknowledge that
                  Look Corporate, as Secured Party, is entitled to register its
                  interest in the Goods supplied or to be supplied to the
                  Customer as Grantor under the Terms and Conditions on the PPSA
                  Register as Collateral.<span></span>
                </p>
                <p>
                  7.5       To the extent permissible at law, the Customer:
                  <span></span>
                </p>
                <p>
                  (a)           waives its right to receive notification of or a
                  copy of any Verification Statement confirming registration of
                  a Financing Statement or a Financing Change Statement relating
                  to a Security Interest granted by the Customer, as Grantor, to
                  Look Corporate.<span></span>
                </p>
                <p>
                  (b)           agrees to indemnify Look Corporate on demand for
                  all costs and expenses, including legal costs and expenses on
                  a solicitor / client basis, associated with the;<span></span>
                </p>
                <p>
                  (i)               registration or amendment or discharge of
                  any Financing Statement registered by or on behalf of Look
                  Corporate; and <span></span>
                </p>
                <p>
                  (ii)              enforcement or attempted enforcement of any
                  Security Interest granted to Look Corporate by the Customer.
                  <span></span>
                </p>
                <p>
                  (c)           agrees that nothing in sections 130 and 143 of
                  the PPSA will apply to the Terms and Conditions or the
                  Security under the Terms and Conditions;<span></span>
                </p>
                <p>
                  (d)           agrees to waive its right to do any of the
                  following under the PPSA:<span></span>
                </p>
                <p>
                  (i)               receive notice of removal of an Accession
                  under section 95;<span></span>
                </p>
                <p>
                  (ii)              receive notice of an intention to seize
                  Collateral under section 123;<span></span>
                </p>
                <p>
                  (iii)             object to the purchase of the Collateral by
                  the Secured Party under section 129;<span></span>
                </p>
                <p>
                  (iv)             receive notice of disposal of Collateral
                  under section 130;<span></span>
                </p>
                <p>
                  (v)              receive a Statement of Account if there is no
                  disposal under section 132(4);<span></span>
                </p>
                <p>
                  (vi)             receive a Statement of Account under section
                  132(3)(d) following a disposal showing the amounts paid to
                  other Secured Parties and whether Security Interests held by
                  other Secured Parties have been discharged.<span></span>
                </p>
                <p>
                  (vii)            receive notice of retention of Collateral
                  under section 135;<span></span>
                </p>
                <p>
                  (viii)           redeem the Collateral under section 142; and
                  <span></span>
                </p>
                <p>
                  (ix)             reinstate the Security Agreement under
                  section 143. <span></span>
                </p>
                <p>
                  7.6       All payments received from the Customer must be
                  applied in accordance with section 14(6)(c) of the PPSA.{" "}
                  <span></span>
                </p>
                <p>
                  <strong>
                    <span> </span>
                  </strong>
                </p>
                <p>
                  <strong>
                    8.         Intellectual Property<span></span>
                  </strong>
                </p>
                <p>
                  8.1       The Goods may contain Intellectual Property rights
                  that belong to Look Corporate.  Property, right and title in
                  that Intellectual Property will remain with Look Corporate
                  despite the Customer acquiring the Goods.<span></span>
                </p>
                <p>
                  8.2       The Customer agrees to do all things reasonably
                  required and execute all documents to ensure Look Corporate
                  retains ownership in the Intellectual Property.<span></span>
                </p>
                <p>
                  8.3       The Customer must not pass any Intellectual Property
                  to third parties without Look Corporate’s prior written
                  approval.  <span></span>
                </p>
                <p>
                  8.4       The Customer agrees to indemnify and keep
                  indemnified Look Corporate against any claim for infringement
                  of Intellectual Property where the claim arises out of
                  information supplied by the Customer or as a result of Look
                  Corporate complying with the Customer’s order. <span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>9.         Specifications </strong>
                  <span></span>
                </p>
                <p>
                  9.1       Specifications, drawings and particulars of weights
                  and dimensions provided by the Customer are subject to the
                  accuracy of the information provided. The Customer
                  acknowledges that Look Corporate may deviate from the
                  specifications provided by the Customer if Look Corporate
                  believes it is reasonably necessary to do so and any such
                  deviation does not vitiate any contract with Look Corporate or
                  form grounds for any claim against Look Corporate by the
                  Customer.<span></span>
                </p>
                <p>
                  9.2       The descriptions, illustrations and performances
                  contained in Look Corporate’s catalogues, price lists and
                  other advertising material do not form part of the contract of
                  sale of the Goods.<span></span>
                </p>
                <p>
                  9.3       Where specifications, drawings or other particulars
                  are supplied by the Customer, Look Corporate’s quotation is
                  made on estimates of quantities required. If there are any
                  adjustments in quantities above or below the quantities
                  estimated by Look Corporate and set out in a quotation, then
                  any such increase or decrease are to be adjusted on a unit
                  rate basis according to unit prices set out in any attached
                  document or in the quotation.<span></span>
                </p>
                <p>
                  9.4       Any performance figures given by Look Corporate are
                  estimates only.  Look Corporate is not liable for loss or
                  damage resulting from failure of the Goods to attain such
                  figures unless specifically guaranteed in writing by Look
                  Corporate. <span></span>
                </p>
                <p>
                  9.5       The Customer must comply with Look Corporate’s
                  instructions and recommendations in relation to the Goods
                  including instructions relating to graphic installations.  To
                  the extent permitted by law, Look Corporate is not liable for
                  any loss or damage resulting from the Customer failing to
                  follow such instructions.  <span></span>
                </p>
                <p>
                  <strong>
                    <span> </span>
                  </strong>
                </p>
                <p>
                  <strong>10.       Force Majeure</strong>
                  <span></span>
                </p>
                <p>
                  10.1     Look Corporate is not liable for delay in performing,
                  or non-performance, of any of its obligations under these
                  Terms and Conditions caused by unforeseeable circumstances
                  beyond Look Corporate’s reasonable control (including, without
                  limitation, acts of God, civil or military authority,
                  accidents, earthquakes, strikes, the elements, labour
                  disputes, shortage of suitable part or components or other
                  materials, mechanical breakdown, fire, flood, tempest and war)
                  and in such circumstances, Look Corporate will be entitled to
                  a reasonable extension of time for the performance of its
                  obligations.<span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>11.       General</strong>
                  <span></span>
                </p>
                <p>
                  11.1     These terms and conditions are to be construed in
                  accordance with the laws from time to time in the State of New
                  South Wales and the Commonwealth of Australia.<span></span>
                </p>
                <p>
                  11.2     These standard trading conditions contain all of the
                  terms and conditions of the contract between the parties and
                  may only be varied by agreement in writing between the
                  parties.<span></span>
                </p>
                <p>
                  11.3     Any conditions found to be void, unenforceable or
                  illegal may, to that extent be severed from the Agreement.
                  <span></span>
                </p>
                <p>
                  11.4     No waiver of any of these terms and conditions or
                  failure to exercise a right or remedy by Look Corporate will
                  be considered to imply or constitute a further waiver by Look
                  Corporate of the same or any other term, condition, right or
                  remedy.<span></span>
                </p>
                <p>
                  11.5     The Customer must keep confidential all information
                  including these Terms and Conditions, the price of the supply
                  of the Goods, and any information which Look Corporate may
                  reasonably consider is confidential, unless the Customer is
                  required by law to disclose such information or the
                  information has become public information or with prior
                  written consent of Look Corporate. <span></span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    12.       Indemnity<span></span>
                  </strong>
                </p>
                <p>
                  12.1     To the full extent permitted by law, Customer will
                  indemnify Look Corporate and keep Look Corporate indemnified
                  from and against any liability and any loss or damage Look
                  Corporate may sustain, as a result of any breach, act or
                  omission, arising directly or indirectly from or in connection
                  with any breach of any of these Terms and Conditions by
                  Customer or its representatives.<span></span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
