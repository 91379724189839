import mainImage from "../../assets/img/COVID/main.jpg";
import gym from "../../assets/img/COVID/gyms.jpg";
import backDrops from "../../assets/img/COVID/customisable-backdrops.jpg";

const sliderData = [
  {
    id: 1,
    description: "backgrounds",
    image: mainImage,
  },
  {
    id: 2,
    description: "gym",
    image: gym,
  },
  {
    id: 3,
    description: "Customisable Backdrops",
    image: backDrops,
  },
];
export default sliderData;
