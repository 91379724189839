import React from "react";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";
import sticker1 from "../../../assets/img/store/floor-stickers-1.png";
import sticker2 from "../../../assets/img/store/floor-stickers-2.png";
import sticker3 from "../../../assets/img/store/floor-stickers-3.png";
import sticker4 from "../../../assets/img/store/floor-stickers-0.png";

export default function FloorStickers() {
  const title = "Floor Stickers";
  const description = (
    <>
      <p className="lead">
        We all need to be reminded about Social Distancing, especially now as we
        start to see the easing of restrictions. Why not get a little bit
        creative with this key messaging and use one of our existing designs for
        internal and external floor stickers.
      </p>
      <ul>
        <li> • Use one of our designs or create your own!</li>
        <li> • Add your logo or institutions colour palette</li>
        <li> • Both indoor and outdoor applications available</li>
        <li> • Size: 300mm x 300mm</li>
        <li>
          {" "}
          • Stickers are slip rated to the highest standard and approved by the
          CSIRO
        </li>
        <li> • Used by councils all across Australia</li>
        <li> • Sold as single designs, in packs of 10</li>
      </ul>{" "}
      <br />
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Floor Stickers";

  const images = [
    { src: sticker1, alt: "Sticker 1" },
    { src: sticker2, alt: "Sticker 2" },
    { src: sticker3, alt: "Sticker 3" },
    { src: sticker4, alt: "Sticker 4" },
  ];

  return (
    <CovidContainerContent
      title={title}
      carouselTitle={carouselTitle}
      description={description}
      images={images}
    />
  );
}
