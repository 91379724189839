import React from "react";
import dysplay from "../../../assets/img/solutions/backlit-displays/image1.jpg";
import dysplay1 from "../../../assets/img/solutions/backlit-displays/DSC_5274.jpg";
import dysplay3 from "../../../assets/img/solutions/backlit-displays/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Backlit Displays";

const images = [
  { src: dysplay, alt: "Backlit Display 1" },
  { src: dysplay1, alt: "Backlit Display 2" },
  { src: dysplay3, alt: "Backlit Display 4" },
];
export default function BacklitDisplay() {
  return <SolutionsContainer title={title} images={images} />;
}
