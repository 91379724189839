import React from "react";
import "./Slider.css";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";

export default function BtnSlider({ direction, moveSlide }) {
  return (
    <>
      {direction === "next" ? (
        <AiOutlineRight
          onClick={moveSlide}
          color="white"
          className="btn-slide next"
        />
      ) : (
        <AiOutlineLeft
          onClick={moveSlide}
          color="white"
          className="btn-slide prev"
        />
      )}
    </>
  );
}
