import React from "react";
import LogoLight from "../../assets/img/logo-light.png";

export default function HomeSliderContent() {
  return (
    <div className="sliderContent">
      <img alt="Logo" className="image-md" src={LogoLight} />
      <h3 className="uppercase">
        Making extraordinary
        <br /> spaces happen
      </h3>
    </div>
  );
}
