import React from "react";

import Mind from "../shared/getInTouch/Mind";
import SmallSlider from "../shared/smallSlider/SmallSlider";

export default function SolutionsContainer({ title, images }) {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h4 className="uppercase color-primary mb40 mb-xs-24">{title}</h4>
            </div>
          </div>
          <SmallSlider content={images} />
        </div>
      </section>
      <Mind />
    </div>
  );
}
