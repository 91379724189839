import React from "react";
import Love from "../getInTouch/Love";
import BrandCarousel from "../brandCarousel/BrandCarousel";
import SmallSlider from "../smallSlider/SmallSlider";

export default function CovidContainerContent({
  title,
  description,
  carouselTitle,
  images,
}) {
  return (
    <div className="main-container">
      <section className="bg mb64 pb0">
        <div className="container">
          <h1 className="uppercase color-primary text-center mb64">{title}</h1>
        </div>

        <div className="container">
          <div className="product-single">
            <div className="row justify-content-md-center">
              <div className="col-md-5">{description}</div>

              <div className="col-md-5">
                <div className="image-slider slider-thumb-controls controls-inside">
                  <span className="label">{carouselTitle}</span>
                  <SmallSlider content={images} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrandCarousel />
      <Love />
    </div>
  );
}
