import React from "react";
import LogoLight from "../assets/img/logo-light.png";
import standishWhite from "../assets/img/standish-white.png";
import ScrollLink from "./helpers/ScrollLink";

export default function Footer() {
  return (
    <footer className="footer-2 bg-dark">
      <div className="d-flex flex-column justify-content-center m-2 mb-5">
        <div className="text-center mb-2">
          <img alt="Logo" className="image-xs mb16" src={LogoLight} />
          <p className="lead mb16 mb-xs-16 text-light">
            277 Parramatta Road Leichhardt, NSW 2040
            <br />
            PO Box 337, Westgate NSW 2048
            <br />
            02 9568 6855
          </p>
        </div>
        <div className="text-center">
          <span className="text-light">
            © Copyright {new Date().getFullYear()} Look
          </span>
        </div>
      </div>
      <div className="container footer-child">
        <div>
          <ScrollLink to="/privacy" className="block">
            Privacy Policy
          </ScrollLink>
        </div>

        <div>
          <a
            href="https://www.standishandco.com.au"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <img
              alt="Standish and Co"
              className="image-small mb8"
              src={standishWhite}
            />
          </a>
        </div>

        <div>
          <ScrollLink to="/TermsAndConditions" className="block">
            Terms and Conditions
          </ScrollLink>
        </div>
      </div>
    </footer>
  );
}
