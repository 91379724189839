import React, { Component } from "react";
import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./App.css";

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route) => (
            <Route key={route.id} {...route} />
          ))}
        </Routes>
      </Layout>
    );
  }
}
