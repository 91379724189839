import React from "react";
import banner from "../../../assets/img/store/pull-up-banners-0.jpg";
import banner1 from "../../../assets/img/store/pull-up-banners-1.jpg";
import banner2 from "../../../assets/img/store/pull-up-banners-2.jpg";
import banner3 from "../../../assets/img/store/pull-up-banners-3.jpg";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";

export default function Banners() {
  const title = "Pull Up Banners";
  const description = (
    <>
      <p className="lead">
        Pull up banners can be used indoors or outdoors, occupying minimal floor
        space. They are quick to assemble and dismantle, and can be packed away
        into convenient and protective carry bags. Our graphics are
        interchangeable enabling you to communicate any message or direction at
        any point in time. Set up and pack away in seconds these pre-assembled
        units.
      </p>
      <ul className="lead">
        <li> • High resolution digital print</li>
        <li> • Premium grade bright White Synthetic</li>
        <li>
          {" "}
          • Choose from 4 different messages (or CREATE your own – just call us)
        </li>
        <li> • Add your logo or colours</li>
      </ul>
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Pull Up Banners";

  const images = [
    { src: banner, alt: "Banner" },
    { src: banner1, alt: "Banner 1" },
    { src: banner2, alt: "Banner 2" },
    { src: banner3, alt: "Banner 3" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
