import React from "react";

export default function Notfound() {
  return (
    <div>
      <br />
      <br />
      <h1 className="text-center mt-5 fullscreen">404 - Not Found!</h1>
    </div>
  );
}
