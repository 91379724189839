import React from "react";
import gallery from "../../../assets/img/solutions/galleries-museums-theatres/image1.jpg";
import gallery1 from "../../../assets/img/solutions/galleries-museums-theatres/image2.jpg";
import gallery2 from "../../../assets/img/solutions/galleries-museums-theatres/image3.jpg";
import gallery3 from "../../../assets/img/solutions/galleries-museums-theatres/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Galleries, Museums & Theatres";

const images = [
  { src: gallery, alt: "Gallery 1" },
  { src: gallery1, alt: "Gallery 2" },
  { src: gallery2, alt: "Gallery 3" },
  { src: gallery3, alt: "Gallery 4" },
];

export default function Galleries() {
  return <SolutionsContainer title={title} images={images} />;
}
