import React from "react";
import { Widget } from "@typeform/embed-react";

export default function Contact() {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <h1 className="uppercase color-primary mb40 mb-xs-24">
                Contact us
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-6 col-md-5">
              <p className="uppercase h4">
                We love to hear about exciting new projects!
              </p>
              <p>
                Get in touch to see how we can help.
                <br />
                Fill in the form here --{">"}
                <br />
                Send us an email
                <br />
                Or just pick up the phone and give us a call
              </p>
              <hr />
              <p>
                Look
                <br />
                277 Parramatta Rd,
                <br /> Leichhardt, NSW 2040
                <br />{" "}
                <a
                  href="https://goo.gl/maps/jpKmAgZm5hK2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View in Google maps
                </a>
              </p>
              <hr />
              <p>
                <strong>Phone:</strong> 02 9568 6855
                <br />
                <strong>Email:</strong> hello@look.com.au
              </p>
            </div>
            <div className="col-sm-6 col-md-5 col-md-offset-1">
              <Widget
                id="TU9tn3"
                style={{ width: "100%", height: "430px" }}
                className="my-form"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p0">
        <div className="map-holder pt180 pb180">
          <iframe
            title="Google Maps View"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.065556977944!2d151.15953155086888!3d-33.887965427390455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b0178e08016d%3A0x598c991cfb7d80ce!2s277+Parramatta+Rd%2C+Stanmore+NSW+2048!5e0!3m2!1sen!2sau!4v1450696557047"
          />
        </div>
      </section>
    </div>
  );
}
