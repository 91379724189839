import React from "react";
import ScrollLink from "../../helpers/ScrollLink";

export default function Mind() {
  return (
    <section className="fold2">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="mb8 white-on-orange">
              Have something else in mind?
            </h2>
            <p className="lead mb40">
              Get in touch with us. We can give you some ideas or bring your
              ideas to life.
            </p>
            <ScrollLink className="btn btn-filled btn-lg mb0" to="/contact">
              Get in touch
            </ScrollLink>
          </div>
        </div>
      </div>
    </section>
  );
}
