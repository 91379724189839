import React from "react";
import CovidContainerContent from "../../shared/covidContainer/CovidContainerContent";
import gym1 from "../../../assets/img/store/fitness-1.jpg";
import gym2 from "../../../assets/img/store/fitness-2.jpg";
import gym3 from "../../../assets/img/store/fitness-3.jpg";
import gym4 from "../../../assets/img/store/fitness-4.jpg";

export default function Gym() {
  const title = "Fitness Industry Solutions";
  const description = (
    <>
      <p className="lead">
        Communicate clearly with your members and optimise the use of space and
        machines safely.
      </p>
      <ul className="lead">
        <li> • Anti-microbial signage</li>
        <li> • Machine signage</li>
        <li> • Hygiene screens</li>
        <li> • Machine dividers</li>
        <li> • Floor communications</li>
        <li> • Multi-surface application</li>
        <li> • Branded hand towels</li>
        <li> • Foot traffic management</li>
        <li> • Cafe signage</li>
        <li> • Portable Hand Sanitisers</li>
      </ul>
      <p className="lead">
        To order, simply call us on 02 9568 6855 or{" "}
        <a href="/contact">contact us here.</a>
      </p>
    </>
  );

  const carouselTitle = "Fitness Industry Solutions";

  const images = [
    { src: gym1, alt: "Gym" },
    { src: gym2, alt: "Gym 1" },
    { src: gym3, alt: "Gym 2" },
    { src: gym4, alt: "Gym 3" },
  ];

  return (
    <CovidContainerContent
      title={title}
      description={description}
      carouselTitle={carouselTitle}
      images={images}
    />
  );
}
