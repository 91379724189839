import React from "react";
import LogoLight from "../../assets/img/logo-light.png";

export default function CovidSliderContent() {
  return (
    <div className="sliderContent">
      <img alt="Logo" className="image-md" src={LogoLight} />
      <h3 className="uppercase ">SIGNAGE &amp; COMMUNICATION SOLUTIONS</h3>
      <p className="text-white h4">
        Keep your customers, patrons, and team safe.
        <br />
        Standard and customisable solutions available on all products. <br />
        Contact our team to order or book a meeting.
      </p>
    </div>
  );
}
