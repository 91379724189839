import React from "react";
import Creative from "../shared/getInTouch/Creative";
import Slider from "../shared/Slider/Slider";
import dataSlider from "./dataSlider";

export default function Home() {
  return (
    <div className="main-container">
      <div className="fullscreen">
        <Slider dataSlider={dataSlider} page="home" />
      </div>
      <Creative />
    </div>
  );
}
