import React from "react";
import slide from "../../../assets/img/solutions/art/image1.jpg";
import slide1 from "../../../assets/img/solutions/art/image2.jpg";
import slide2 from "../../../assets/img/solutions/art/image3.jpg";
import slide3 from "../../../assets/img/solutions/art/image4.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "art";

const images = [
  { src: slide, alt: "Art 1" },
  { src: slide1, alt: "Art 2" },
  { src: slide2, alt: "Art 3" },
  { src: slide3, alt: "Art 4" },
];
export default function Art() {
  return <SolutionsContainer title={title} images={images} />;
}
