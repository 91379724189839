import art from "../../assets/img/solutions/art.jpg";
import backlitDisplay from "../../assets/img/solutions/backlit-displays.jpg";
import environment from "../../assets/img/solutions/branded-environments.jpg";
import cladding from "../../assets/img/solutions/cladding.jpg";
import display from "../../assets/img/solutions/displays.jpg";
import displaySystem from "../../assets/img/solutions/display-systems.jpg";
import events from "../../assets/img/solutions/events.jpg";
import galleries from "../../assets/img/solutions/galleries-museums-theatres.jpg";
import hoardings from "../../assets/img/solutions/hoardings.jpg";
import horeca from "../../assets/img/solutions/horeca.jpg";
import fitout from "../../assets/img/solutions/office-fitout.jpg";
import outdoor from "../../assets/img/solutions/outdoor.jpg";
import popup from "../../assets/img/solutions/pop-up.jpg";
import wallpaper from "../../assets/img/solutions/wallpaper.jpg";
import windows from "../../assets/img/solutions/windows.jpg";
import fabrics from "../../assets/img/solutions/fabrics.jpg";

const containerData = [
  {
    alt: "art",
    src: art,
    description: "ART",
    href: "/solutions/art",
  },
  {
    alt: "Backlit Displays",
    src: backlitDisplay,
    description: "BACKLIT DISPLAYS",
    href: "/solutions/backlit-displays",
  },
  {
    alt: "Branded Environments",
    src: environment,
    description: "BRANDED ENVIRONMENTS",
    href: "/solutions/branded-environments",
  },
  {
    alt: "Cladding",
    src: cladding,
    description: "CLADDING",
    href: "/solutions/cladding",
  },
  {
    alt: "Displays",
    src: display,
    description: "DISPLAYS",
    href: "/solutions/displays",
  },
  {
    alt: "Display Systems",
    src: displaySystem,
    description: "DISPLAY SYSTEMS",
    href: "/solutions/display-system",
  },
  {
    alt: "Events",
    src: events,
    description: "EVENTS",
    href: "/solutions/events",
  },
  {
    alt: "Galleries Museums Theatres",
    src: galleries,
    description: "GALLERIES, MUSEUMS & THEATRES",
    href: "/solutions/galleries",
  },
  {
    alt: "Hoardings",
    src: hoardings,
    description: "HOARDINGS",
    href: "/solutions/hoardings",
  },
  {
    alt: "HORECA",
    src: horeca,
    description: "HORECA",
    href: "/solutions/horeca",
  },
  {
    alt: "Office Fitout",
    src: fitout,
    description: "OFFICE FITOUT",
    href: "/solutions/office-fitout",
  },
  {
    alt: "Outdoor",
    src: outdoor,
    description: "OUTDOOR",
    href: "/solutions/outdoor",
  },
  {
    alt: "Pop Up",
    src: popup,
    description: "POP UP",
    href: "/solutions/popup",
  },
  {
    alt: "Wallpaper",
    src: wallpaper,
    description: "WALLPAPER",
    href: "/solutions/wallpaper",
  },
  {
    alt: "Windows",
    src: windows,
    description: "WINDOWS",
    href: "/solutions/windows",
  },
  {
    alt: "Fabrics",
    src: fabrics,
    description: "FABRICS",
    href: "/solutions/fabrics",
  },
];

export default containerData;
