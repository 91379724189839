import React from "react";
import system from "../../../assets/img/solutions/display-systems/image1.jpg";
import system1 from "../../../assets/img/solutions/display-systems/image2.jpg";
import system2 from "../../../assets/img/solutions/display-systems/image3.jpg";
import system3 from "../../../assets/img/solutions/display-systems/image4.jpg";
import magenet from "../../../assets/img/solutions/display-systems/BigBash_LeaderBoard-10.jpg";
import SolutionsContainer from "../SolutionsContainer";

const title = "Display Systems";
const images = [
  { src: system, alt: "Display System 1" },
  { src: system1, alt: "Display System 2" },
  { src: magenet, alt: "Display System 2 zoom" },
  { src: system2, alt: "Display System 3" },
  { src: system3, alt: "Display System 4" },
];

export default function DisplaySystem() {
  return <SolutionsContainer title={title} images={images} />;
}
