import React from "react";

export default function Privacy() {
  return (
    <div className="main-container">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <h1 className="uppercase color-primary mb40 mb-xs-24">Privacy</h1>
              <p className="lead">
                We care about your privacy. To prove it, we have written you
                this big, long collection of words.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div>
            <p>
              <b>Look Privacy Statement and Policy </b>
            </p>
            <p>
              This policy and statement refers to Look Corporate Pty Ltd t/as
              Look ABN 58 001 717 193 of 277 Parramatta Rd Leichhardt NSW 2040
              and its related companies. Look is bound by the APP’s within the
              Privacy Act.  In order to carry out our business, we collect and
              store the following types of personal information.
            </p>
            <p>
              <b>Information collected</b>
            </p>
            <p>
              Personal information collected include your name, company details,
              contact address, email and telephone numbers. Credit information
              is collected (your repayment and default history, amount and type
              of credit provided, and any default information) when requesting
              credit terms. These details are collected electronically, by
              hardcopy, through job applications, credit account applications,
              or verbally. The type of information collected depends upon the
              products or services you use.
            </p>
            <p>
              Look also collects details of the products and services we have
              supplied to you or that you have enquired about.
            </p>
            <p>
              <strong>Cookies/IP Details</strong>
            </p>
            <p>
              When browsing the website, Look makes a record of your visit and
              logs the following information for statistical purposes; your
              server address, your top level domain name (for example .com,
              .gov, .au, .uk etc), the pages you accessed and documents
              downloaded, the previous site you have visited, the type of
              browser you are using. Look uses electronic images known as Web
              bugs – sometimes called single-pixel GIFs, transparent GIFs, or
              clear gifs- that allow us to track general user traffic patterns.
            </p>
            <p>
              Your Internet browser has a feature called “cookies” which store
              small amounts of data on your computer about your visit to any of
              our sites. Cookies tells us nothing about who you are unless you
              specifically give us personally identifiable information. You do
              not need to have cookies turned on to visit our website. In
              addition, you may elect not to allow cookies to be collected by
              selecting certain options on your browser.
            </p>
            <p>
              This information is stored securely and accessed for appropriate
              reasons.  Your information is needed in order for us to proceed
              with quotes and orders.  If you do not provide the information,
              Look may be unable to proceed with your requests.
            </p>
            <p>
              <b>Use of Information</b>
            </p>
            <p>We may use this information for the following purposes:</p>
            <ul>
              <li>keeping count of return visits to our site</li>
              <li>
                accumulate and report anonymous, aggregate (data collected in
                mass), statistical information on Web site usage
              </li>
              <li>determining which pages and features users like best</li>
              <li>direct marketing in relation to promotional activities</li>
              <li>processing quotes and orders</li>
              <li>carry out credit checks and credit reporting</li>
              <li>historical data on products and services ordered</li>
              <li>processing payments</li>
            </ul>
            <p>
              Look will request your consent to use your personal information
              for any other purpose. This website does not provide facilities
              for the secure transmission of information across the Internet.
              Users should be aware that there are inherent risks transmitting
              information across the Internet.
            </p>
            <p>
              <strong> A</strong>
              <b>ccess to information collected</b>
            </p>
            <p>
              This information may be disclosed to our associated entities,
              parties related to your employment (eg referees), contractors,
              other Credit providers, risk insurers, debt collectors, lawyers
              and credit reporting bodies.
            </p>
            <p>
              We will not make an attempt to identify users or their browsing
              activities. However, in the unlikely event of an investigation, a
              law enforcement agency or other government agency may exercise its
              legal authority to inspect our Internet Service Provider’s logs
              and other information collected.
            </p>
            <p>
              Persons will be given access to their personal information upon
              request.
            </p>
            <p>
              <b>How to Update your Information </b>
            </p>
            <p>
              Look takes all care in ensuring the information collected is
              up-to-date and as accurate as possible. However, the accuracy of
              the information is largely dependant on what you provide us.  If
              your details change, please call our sales team on 02 9568 6855 or
              email <a href="mailto:sales@look.com.au">sales@look.com.au</a>  to
              update your information.
            </p>
            <p>
              If you no longer wish to be on our mailing list, please do not
              hesitate to phone our sales team on (02)9568 6855 or email{" "}
              <a href="mailto:sales@look.com.au">sales@look.com.au</a> and
              advise you would like to be removed from the mailing lists.
            </p>
            <p>
              <b>Complaints Procedure</b>
            </p>
            <p>
              If you have any complaints or concerns about our Privacy Policy
              and Statement  or the type of information we hold about you,
              please contact our Privacy Officer at PO Box 337 Westgate NSW 2040
              or email{" "}
              <a href="mailto:privacy@look.com.au">privacy@look.com.au</a>. 
              Look has procedures in place on managing complaints relating to
              privacy matters.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
