import { v4 as uuidv4 } from "uuid";
import harbour from "../../assets/img/look/image1.jpg";
import lacoste from "../../assets/img/look/Lacoste_2016_Myer-9.jpg";
import ferragamo from "../../assets/img/look/Ferragamo-7.jpg";
import estee from "../../assets/img/look/Estee_Lauder_Car.jpg";

const dataSlider = [
  {
    id: uuidv4(),
    description: "Estee",
    image: estee,
  },
  {
    id: uuidv4(),
    description: "lacoste",
    image: lacoste,
  },
  {
    id: uuidv4(),
    description: "habour",
    image: harbour,
  },
  {
    id: uuidv4(),
    description: "ferragamo",
    image: ferragamo,
  },
];

export default dataSlider;
